import React from 'react';
import '../../assets/css/cardtreni.css'; // Assicurati di importare il file CSS

const CardTreni = ({ treni }) => {
  return (
    <div className="cardtreni-container">
      {treni.map((treno, index) => {
        let tipologiaTreno = treno.compTipologiaTreno;
        let iconaTipologia = null;

        // Definizione delle icone per i treni suburbani
        

        // Logica per treni con 4 cifre
        if (treno.numeroTreno.toString().length === 4) {
          tipologiaTreno = treno.codiceCliente === 63 ? 'Regio Express' : 'Regionale Veloce';
        }

        else if (treno.numeroTreno.toString().length === 5) { if (treno.numeroTreno.toString().startsWith('24') || treno.numeroTreno.toString().startsWith('25')) { if (treno.numeroTreno.toString().startsWith('241')) { treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/6/60/Milano_S1.svg"; } else if (treno.numeroTreno.toString().startsWith('242')) { treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/d/d6/Milano_S2.svg"; } else if (treno.numeroTreno.toString().startsWith('245')) { treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/0/00/Milano_S5.svg"; } else if (treno.numeroTreno.toString().startsWith('246')) { treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/3/34/Milano_S6.svg"; } else if (treno.numeroTreno.toString().startsWith('247')) { treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/3/34/Milano_S6.svg"; } else if (treno.numeroTreno.toString().startsWith('248')) { treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/9/94/Milano_S8.svg"; } else if (treno.numeroTreno.toString().startsWith('249')) { treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/0/01/Milano_S9.svg"; } else if (treno.numeroTreno.toString().startsWith('243')) { treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/3/3a/Milano_S13.svg"; } else if (treno.numeroTreno.toString().startsWith('250')) { treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/2/28/Milano_S11.svg"; } else if (treno.numeroTreno.toString().startsWith('256')) { treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/8/8e/Milano_S12.svg"; } tipologiaTreno = "Suburbano"; } else {  tipologiaTreno = "Regionale"; } }  
        // Logica per treni con 5 cifre
       

        // Logica per assegnare la tipologia di treno in base a categoriaDescrizione
        

        switch (treno.categoriaDescrizione) {
          case 'IC':
            tipologiaTreno = 'Intercity';
            break;
          case 'EC':
            tipologiaTreno = 'Eurocity';
            
            break;
          case 'EN':
            tipologiaTreno = 'Euronight';
            
            break;
          case 'ICN':
            tipologiaTreno = 'Intercity Notte';
            
            break;
          case 'FR':
            tipologiaTreno = 'Frecciarossa';
            
            break;
          case 'FB':
            tipologiaTreno = 'Frecciabianca';
            
            break;
          case 'FA':
            tipologiaTreno = 'Frecciargento';
            
            break;
          default:
            break;
        }

        return (
          <div key={index} className="cardtreni-card">
            <div className="cardtreni-header">
              <div className="cardtreni-header-info">
                <h3 className="cardtreni-numero-treno">Treno {treno.compNumeroTreno}</h3>
                <p className="cardtreni-destinazione"><strong>Destinazione:</strong> {treno.destinazione}</p>
                <p className="cardtreni-destinazione"><strong>Tipologia:</strong> {tipologiaTreno}</p>
              </div>
            </div>
            <div className="cardtreni-body">
            <img
                src={treno.iconaTreno}
                alt="Logo Compagnia Ferroviaria"
                className="cardtreni-compagnia-logo"
              />
              <p><strong>Partenza:</strong> {new Date(treno.orarioPartenza).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
              <p><strong>Ritardo:</strong> {treno.ritardo} minuti</p>
              <p><strong>Binario:</strong> {treno.binarioEffettivoPartenzaDescrizione || 'Da definire'}</p>
              <div className="cardtreni-tipologia">
                <strong>Tipologia:</strong>
                
                  <img
                    src={treno.tipologiaTrenoIcona}
                    alt={`Icona ${tipologiaTreno}`}
                    className="cardtreni-tipologia-icona"
                  />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardTreni;










