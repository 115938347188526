import React, { useState, useEffect } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { FaInstagram, FaGamepad, FaWhatsapp, FaTiktok } from 'react-icons/fa';
import { HiMenu, HiX } from 'react-icons/hi';
import { ReactComponent as Anonymouss } from '../../assets/img/anonymouss.svg'; // Importa il logo SVG
import NGLLogo from '../../assets/img/ngllogo.svg'; // Importa il logo NGL Cila SVG
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, get } from 'firebase/database';
import '../../assets/css/animations.css'; // Importa il file CSS per lo spinner e le animazioni

function Navbar() {
  const [activeLink, setActiveLink] = useState('');
  const [entering, setEntering] = useState(false);
  const [username, setUsername] = useState('');
  const [uid, setUid] = useState(null);


  const handleNavLinkClick = (link) => {
    setActiveLink(link);
    setEntering(true);
    setTimeout(() => setEntering(false), 500); // Reset entering state after 500ms
  };

  useEffect(() => {
    const auth = getAuth();
    const db = getDatabase();
  
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
        const userRef = ref(db, `users/${user.uid}`);
        get(userRef).then((snapshot) => {
          if (snapshot.exists()) {
            setUsername(snapshot.val().username);
          }
        }).catch((error) => {
          console.error("Errore durante il recupero del username: ", error);
        });
      }
    });
  }, []);
  

  return (
    <Disclosure as="nav" className="bg-black text-white p-4 shadow-lg sticky top-0 z-10">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white menu-button-transition">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <HiX className="block h-6 w-6 menu-open-close" aria-hidden="true" />
                  ) : (
                    <HiMenu className="block h-6 w-6 menu-open-close" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img src={NGLLogo} alt="NGL Cila Logo" className="w-8 h-8 mr-2 select-none pointer-events-none" style={{ width: '30px', height: '30px' }} />
                  <span className="text-white text-2xl font-bold hover:text-yellow-500 transition-transform duration-300 ease-in-out">
                    NGL Cila
                  </span>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    <a
                      href="/news"
                      onClick={() => handleNavLinkClick('/news')}
                      className={`nav-link px-3 py-2 rounded-md text-sm font-medium transition-colors duration-500 ease-in-out ${activeLink === '/news' ? 'text-yellow-500' : 'text-white hover:text-yellow-500'}`}
                      aria-label="News section"
                    >
                      NOTIZIE
                    </a>
                    <a
                      href="/archiviofoto"
                      onClick={() => handleNavLinkClick('/archiviofoto')}
                      className={`nav-link px-3 py-2 rounded-md text-sm font-medium transition-colors duration-500 ease-in-out ${activeLink === '/archiviofoto' ? 'text-yellow-500' : 'text-white hover:text-yellow-500'}`}
                      aria-label="Galleria section"
                    >
                      FOTO
                    </a>
                    <a
                      href="/video"
                      onClick={() => handleNavLinkClick('/video')}
                      className={`nav-link px-3 py-2 rounded-md text-sm font-medium transition-colors duration-500 ease-in-out ${activeLink === '/video' ? 'text-yellow-500' : 'text-white hover:text-yellow-500'}`}
                      aria-label="Video section"
                    >
                      VIDEO
                    </a>
                    <a
                      href="/altro"
                      onClick={() => handleNavLinkClick('/altro')}
                      className={`nav-link px-3 py-2 rounded-md text-sm font-medium transition-colors duration-500 ease-in-out ${activeLink === '/altro' ? 'text-yellow-500' : 'text-white hover:text-yellow-500'}`}
                      aria-label="Altro section"
                    >
                      ALTRO
                    </a>
                    <a
                      href="/shop"
                      onClick={() => handleNavLinkClick('/shop')}
                      className={`nav-link px-3 py-2 rounded-md text-sm font-medium transition-colors duration-500 ease-in-out ${activeLink === '/altro' ? 'text-yellow-500' : 'text-white hover:text-yellow-500'}`}
                      aria-label="Store section"
                    >
                      NEGOZIO
                    </a>
                  </div>
                </div>
              </div>
              <div className="hidden sm:block">
                <div className="flex items-center space-x-4">
                  <a href="https://www.instagram.com/ngl_cila" aria-label="Instagram" className="transition-transform transform hover:scale-125 text-white"><FaInstagram size={30} /></a>
                  <a href="https://whatsapp.com/channel/0029VajF80s6BIEcButOf50E" aria-label="WhatsApp" className="transition-transform transform hover:scale-125 text-white"><FaWhatsapp size={30} /></a>
                  <a href="https://www.tiktok.com/@ngl_cila?_t=8pip3VNfjG4&_r=1" aria-label="Tik Tok" className="transition-transform transform hover:scale-125 text-white"><FaTiktok size={30} /></a>
                  <a href="/minigioco" aria-label="Minigioco" className="transition-transform transform hover:scale-125 text-white"><FaGamepad size={30} /></a>
                  <a href="https://anonymouss.me/ngl_cila" aria-label="Minigioco" className="transition-transform transform hover:scale-125 text-white">
                    <Anonymouss style={{ width: '30px', height: '30px' }} />
                  </a>
                  {uid ? (
  <a href="/account-dashboard" className="px-4 py-2 rounded-full bg-yellow-500 text-white font-bold button-animation">
    {username}
  </a>
) : (
  <button onClick={() => window.location.href='/login'} className="px-4 py-2 rounded-full bg-yellow-500 text-white font-bold button-animation">
    ACCEDI
  </button>
)}

                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <Transition
              show={open}
              enter="navbar-panel-enter"
              leave="navbar-panel-leave"
            >
              <div className="px-2 pt-2 pb-3 space-y-1">
                <a
                  href="/news"
                  onClick={() => handleNavLinkClick('/news')}
                  className={`nav-link block px-3 py-2 rounded-md text-base font-medium transition-colors duration-500 ease-in-out ${activeLink === '/news' ? 'text-yellow-500' : 'text-white hover:text-yellow-500'}`}
                  aria-label="News section"
                >
                  NOTIZIE
                </a>
                <a
                  href="/archiviofoto"
                  onClick={() => handleNavLinkClick('/archiviofoto')}
                  className={`nav-link block px-3 py-2 rounded-md text-base font-medium transition-colors duration-500 ease-in-out ${activeLink === '/archiviofoto' ? 'text-yellow-500' : 'text-white hover:text-yellow-500'}`}
                  aria-label="Galleria section"
                >
                  FOTO
                </a>
                <a
                  href="/video"
                  onClick={() => handleNavLinkClick('/video')}
                  className={`nav-link block px-3 py-2 rounded-md text-base font-medium transition-colors duration-500 ease-in-out ${activeLink === '/video' ? 'text-yellow-500' : 'text-white hover:text-yellow-500'}`}
                  aria-label="Video section"
                >
                  VIDEO
                </a>
                <a
                  href="/altro"
                  onClick={() => handleNavLinkClick('/altro')}
                  className={`nav-link block px-3 py-2 rounded-md text-base font-medium transition-colors duration-500 ease-in-out ${activeLink === '/altro' ? 'text-yellow-500' : 'text-white hover:text-yellow-500'}`}
                  aria-label="Altro section"
                >
                  ALTRO
                </a>
                <a
                  href="/shop"
                  onClick={() => handleNavLinkClick('/shop')}
                  className={`nav-link block px-3 py-2 rounded-md text-base font-medium transition-colors duration-500 ease-in-out ${activeLink === '/altro' ? 'text-yellow-500' : 'text-white hover:text-yellow-500'}`}
                  aria-label="Store section"
                >
                  NEGOZIO
                </a>
                <div className="flex items-center space-x-4 mt-4">
                  <a href="https://anonymouss.me/ngl_cila" aria-label="Minigioco" className="transition-transform transform hover:scale-125 text-white">
                    <Anonymouss style={{ width: '30px', height: '30px' }} />
                  </a>
                  {uid ? (
  <a href="/account-dashboard" className="px-4 py-2 rounded-full bg-yellow-500 text-white font-bold button-animation">
    {username}
  </a>
) : (
  <button onClick={() => window.location.href='/login'} className="px-4 py-2 rounded-full bg-yellow-500 text-white font-bold button-animation">
    ACCEDI
  </button>
)}

                </div>
              </div>
            </Transition>
          </Disclosure.Panel>

          {entering && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-500 ease-in-out z-20">
              <div className="spinner"></div>
            </div>
          )}
        </>
      )}
    </Disclosure>
  );
}

export default Navbar;













