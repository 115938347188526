import React, { useState, useEffect, useRef } from "react";
import { FaTrain, FaInfoCircle, FaSearch, FaNewspaper, FaTable } from 'react-icons/fa';
import { IoTicket } from "react-icons/io5";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import TrovaTreniStazione from '../components/layout/TrovaTreniStazione';
import NotizieFerrovie from "../components/layout/NotizieFerrovie";
import InserisciCodiceTreno from "../components/layout/InserisciCodiceTreno";
import DettagliStazione from "../components/layout/DettagliStazione";
import RFIButton from "../components/layout/RFIButton";

const services = [
  { name: 'Trova le partenze', icon: FaSearch },
  { name: 'Stato del treno', icon: FaTrain },
  { name: 'Informazioni sulla Stazione', icon: FaInfoCircle },
  { name: 'Trovare la soluzione viaggio', icon: IoTicket },
  { name: 'Mostra Notizie RFI', icon: FaNewspaper },
  { name: 'Mostra tabellone real Time', icon: FaTable },
];

function ScopriIlTuoTreno() {
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(false);
  const serviceComponentRef = useRef(null);

  const handleServiceSelect = (serviceName) => {
    setLoading(true);
    setTimeout(() => {
      setSelectedService(serviceName);
      setLoading(false);
      serviceComponentRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  useEffect(() => {
    document.title = 'Scopri il tuo Treno'; // Imposta il titolo della pagina
  }, []);

  const renderSelectedComponent = () => {
    switch (selectedService) {
      case 'Trova le partenze':
        return <TrovaTreniStazione />;
      case 'Stato del treno':
        return <InserisciCodiceTreno />;
      case 'Informazioni sulla Stazione':
        return <DettagliStazione />;
      case 'Mostra Notizie RFI':
        return <NotizieFerrovie />;
      case 'Mostra tabellone real Time':
        return <RFIButton />;
      case 'Trovare la soluzione viaggio':
        window.location.href = "https://www.lefrecce.it/Channels.Website.WEB/";
        return null;
      default:
        return (
          <div className="text-center mt-8">
            <p className="text-xl text-gray-700">Seleziona un servizio per visualizzare ulteriori informazioni.</p>
          </div>
        );
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-8 text-center">
          Seleziona un Servizio
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:scale-105 cursor-pointer text-center"
              onClick={() => handleServiceSelect(service.name)}
            >
              <service.icon className="h-12 w-12 mb-4 text-blue-500" />
              <h3 className="font-bold text-xl mb-2 text-gray-900">{service.name}</h3>
            </div>
          ))}
        </div>
        <div id="service-component" ref={serviceComponentRef} className="mt-8">
          {loading ? (
            <div className="flex flex-col items-center mt-8">
              <svg
                className="animate-spin h-8 w-8 text-blue-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                ></path>
              </svg>
              <p className="mt-2">Attendere Prego...</p>
            </div>
          ) : (
            renderSelectedComponent()
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ScopriIlTuoTreno;



