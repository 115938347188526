import React, { useState, useEffect } from 'react';
import LoadingPopup from "./LoadingPopup";

const NotizieFerrovie = () => {
  const [notizie, setNotizie] = useState([]);
  const [loading, setLoading] = useState(true); // Stato per il caricamento
  const [showLoadingPopup, setShowLoadingPopup] = useState(true);  // Popup di caricamento

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const [tickerResponse, rssResponse] = await Promise.all([
          fetch('https://trenitaliapijs-production.up.railway.app/news/ticker'),
          fetch('https://trenitaliapijs-production.up.railway.app/news/rss')
        ]);
        const tickerData = await tickerResponse.json();
        const rssData = await rssResponse.json();
        setNotizie([...tickerData, ...rssData]);
      } catch (error) {
        console.error("Errore nel fetch delle notizie:", error);
      } finally {
        setLoading(false); // Imposta il caricamento a false una volta ottenuti i dati
      }
    };

    fetchNews();
    setTimeout(() => {
      setShowLoadingPopup(false);  // Nasconde il popup di caricamento dopo la durata della GIF
    }, 3000);  // Durata della GIF in millisecondi
  }, []);

  return (
    <div className="overflow-hidden relative bg-gray-100 py-4">
      {showLoadingPopup && <LoadingPopup duration={3000} onEnd={() => setShowLoadingPopup(false)} />}  {/* Mostra il popup di caricamento */}
      {loading ? (
        <div className="flex flex-col items-center justify-center h-full">
          {/* Spinner di caricamento */}
        </div>
      ) : (
        <div className="flex flex-col items-center">
          {notizie.map((notizia, index) => (
            <div 
              key={index} 
              className="bg-blue-100 mb-4 p-4 w-full min-w-fit shadow-md rounded-md"
            >
              <h5 className="font-bold">{notizia.titolo}</h5>
              <p className="text-gray-700">{new Date(notizia.data).toLocaleString()}</p>
              <div dangerouslySetInnerHTML={{ __html: notizia.testo }}></div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotizieFerrovie;


