import React, { useState, useEffect } from "react";
import { fetchPhotos } from '../logic/photoutils';
import '../../assets/css/galleria-photo.css'; // Assicurati di importare il file CSS

const Photos = () => {
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPhotos(setPhotos).finally(() => setLoading(false));
  }, []);

  const openModal = (photo) => {
    setSelectedPhoto(photo);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedPhoto(null);
  };

  // Ordina le foto per data di pubblicazione (più recente prima)
  const sortedPhotos = photos.sort(
    (a, b) => new Date(b.get('createdAt')) - new Date(a.get('createdAt'))
  );

  return (
    <div className="galleria-photo-container">
      <h2 className="galleria-photo-title">
        LA GALLERIA DI NGL CILA
      </h2>
      {loading ? (
        <div className="galleria-photo-loading">
          <div className="galleria-photo-spinner"></div>
        </div>
      ) : (
        <div className="galleria-photo-grid">
          {sortedPhotos.map((photo) => (
            <div
              key={photo.id}
              className="galleria-photo-item"
              onClick={() => openModal(photo)}
            >
              <img
                src={photo.get("imageUrl")}
                alt="Photo"
                className="galleria-photo-image"
              />
            </div>
          ))}
        </div>
      )}
      {isOpen && (
        <div
          className="galleria-photo-modal-overlay"
          onClick={closeModal}
        >
          <div
            className="galleria-photo-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="galleria-photo-close-button"
              onClick={closeModal}
            >
              ×
            </button>
            <h3 className="galleria-photo-modal-title">
              Descrizione della foto
            </h3>
            <p className="galleria-photo-modal-description">
              {selectedPhoto?.get("description")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Photos;



