import React from 'react';
import '../../assets/css/home-informations.css';
import { FaUsers, FaHandshake, FaDigitalTachograph, FaPhoneAlt } from 'react-icons/fa';
import illustration from '../../assets/img/gossipig.png'; // Assicurati di avere un'immagine di illustrazione

const App = () => {
  return (
    <div className="ihome-ngl-cila">
      {/* Sezione Hero */}
     

      {/* Sezione Chi Siamo */}
      <section id="about" className="ihome-about">
        <div className="ihome-container">
          <h1 className="ihome-title">Cos'è NGL Cila?</h1>
          <p className="ihome-description">
            NGL Cila è un progetto di divulgazione di gossip e notizie del territorio. Il nostro obiettivo è creare un'interconnessione tra i ragazzi, promuovere collaborazioni e utilizzare mezzi digitali sofisticati. Puntiamo ad essere cordiali e a far diventare questo luogo di grande successo.
          </p>
        </div>
      </section>

      {/* Sezione Cosa Facciamo */}
      <section className="ihome-services">
        <div className="ihome-container">
          <h2 className="ihome-subtitle">Cosa Facciamo</h2>
          <div className="ihome-cards">
            <div className="ihome-card">
              <FaUsers className="ihome-icon" />
              <h3>Interconnessione</h3>
              <p>Creiamo legami tra i ragazzi attraverso eventi, social media e piattaforme digitali.</p>
            </div>
            <div className="ihome-card">
              <FaHandshake className="ihome-icon" />
              <h3>Collaborazioni</h3>
              <p>Promuoviamo collaborazioni con altre organizzazioni per raggiungere obiettivi comuni.</p>
            </div>
            <div className="ihome-card">
              <FaDigitalTachograph className="ihome-icon" />
              <h3>Mezzi Digitali</h3>
              <p>Utilizziamo strumenti digitali avanzati per creare contenuti di alta qualità.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Sezione Collaborazioni */}
      <section className="ihome-collaborations">
        <div className="ihome-container">
          <h2 className="ihome-subtitle">Collaborazioni</h2>
          <p className="ihome-collaboration-description">
            Le nostre collaborazioni ci permettono di raggiungere obiettivi comuni e di creare contenuti di alta qualità. Lavoriamo con diverse organizzazioni per fornire il meglio alla nostra community.
          </p>
        </div>
      </section>

      {/* Sezione Contattaci */}
    </div>
  );
}

export default App;



