import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCombobox } from 'downshift';
import CardTreni from "./CardTreni";
import TableTreni from "./TableTreni";
import stations from "../../assets/olther/stations.json";
import '../../assets/css/treniStazione.css';
import LoadingPopup from "./LoadingPopup";

export default function ListaTreni() {
    const [treni, setTreni] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(true);  // Popup iniziale
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);  // Popup di caricamento
    const [viewType, setViewType] = useState(null);
    const [stazionePartenza, setStazionePartenza] = useState(null);
    const [orarioRicerca, setOrarioRicerca] = useState(new Date());
    const [stationsList, setStationsList] = useState(stations.slice(0, 20));
    const [moreStations, setMoreStations] = useState(stations.length > 20);
    const [inputValue, setInputValue] = useState('');
    const [showResults, setShowResults] = useState(false);  // Stato per mostrare i risultati

    useEffect(() => {
        const isMobile = window.matchMedia("(max-width: 767px)").matches;
        if (isMobile) {
            setViewType('card');
            setShowPopup(false);
        }
    }, []);

    const handleFetchTreni = () => {
        if (!stazionePartenza) {
            alert("Per favore seleziona una stazione.");
            return;
        }

        const partenzaId = stazionePartenza.code;
        setLoading(true);
        setShowLoadingPopup(true);  // Mostra il popup di caricamento
        setShowResults(false);  // Nasconde i risultati fino a quando la GIF non ha finito di girare

        axios.get(`https://trenitaliapijs-production.up.railway.app/stations/${partenzaId}/departures`)
            .then(response => {
                const treniAggiornati = response.data.map(treno => {
                    const orarioPartenza = new Date(treno.orarioPartenza);
                    const tempoTrascorso = (orarioRicerca - orarioPartenza) / (1000 * 60);

                    if (!treno.circolante && treno.nonPartito) {
                        treno.statoTreno = "Non partito";
                    } else if (!treno.circolante && tempoTrascorso > 0 && tempoTrascorso <= 30 && treno.ritardo === 0) {
                        treno.statoTreno = "Cancellato/Soppresso";
                    } else if (orarioRicerca > orarioPartenza && !treno.binarioEffettivoPartenzaDescrizione && treno.ritardo === 0) {
                        treno.statoTreno = "CANCELLATO";
                    }
                    return treno;
                });
                setTreni(treniAggiornati);
                setLoading(false);
            })
            .catch(error => {
                console.error("C'è stato un errore nel recupero dei treni!", error);
                alert(`Errore nel recupero dei treni: ${error.message}`);
                setLoading(false);
            });

        setTimeout(() => {
            setShowLoadingPopup(false);  // Nasconde il popup di caricamento dopo la durata della GIF
            setShowResults(true);  // Mostra i risultati al termine della GIF
        }, 3000);  // Durata della GIF in millisecondi
    };

    const handleViewTypeSelection = (type) => {
        setViewType(type);
        setShowPopup(false);
        handleFetchTreni();
    };

    const loadMoreStations = () => {
        const newLength = stationsList.length + 20;
        setStationsList(stations.slice(0, newLength));
        setMoreStations(newLength < stations.length);
    };

    const filteredStations = (inputValue) => {
        return stations.filter(station =>
            station.long_name.toLowerCase().includes(inputValue.toLowerCase())
        ).slice(0, 20);
    };

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getLabelProps,
        getToggleButtonProps,
        getItemProps,
        highlightedIndex,
        selectedItem,
    } = useCombobox({
        items: filteredStations(inputValue),
        onInputValueChange: ({ inputValue }) => {
            setInputValue(inputValue);
            setStationsList(filteredStations(inputValue));
        },
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem) {
                if (selectedItem.code === "load_more") {
                    loadMoreStations();
                } else {
                    setStazionePartenza(selectedItem);
                }
            }
        },
        itemToString: (item) => (item ? item.long_name : ''),
    });

    const defaultOptions = stationsList.map(station => ({
        long_name: station.long_name,
        code: station.code
    }));

    if (moreStations) {
        defaultOptions.push({ long_name: "Mostra altre stazioni...", code: "load_more" });
    }

    return (
        <div className="container mx-auto p-4">
            {showPopup && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg p-6 shadow-lg text-center">
                        <h2 className="text-xl font-bold mb-4">Come vuoi visualizzare i treni?</h2>
                        <button
                            className="px-4 py-2 bg-blue-600 text-white rounded-lg mr-2 hover:bg-blue-700"
                            onClick={() => handleViewTypeSelection('card')}
                        >
                            Card
                        </button>
                        <button
                            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
                            onClick={() => handleViewTypeSelection('table')}
                        >
                            Tabella
                        </button>
                    </div>
                </div>
            )}
            {showLoadingPopup && <LoadingPopup duration={3000} onEnd={() => setShowLoadingPopup(false)} />}  {/* Mostra il popup di caricamento */}
            <div className="zcns-container">
                <label className="zcns-label" {...getLabelProps()}>Stazione di Partenza</label>
                <div {...getToggleButtonProps()}>
                    <input
                        {...getInputProps()}
                        className="zcns-select-input"
                        placeholder="Cerca stazione"
                    />
                </div>
                <ul {...getMenuProps()} className="zcns-menu">
                    {isOpen &&
                        defaultOptions.map((item, index) => (
                            <li
                                key={index}
                                {...getItemProps({ item, index })}
                                className={`zcns-menu-item ${highlightedIndex === index ? 'zcns-highlighted-item' : ''}`}
                            >
                                {item.long_name}
                            </li>
                        ))}
                </ul>
            </div>
            <div className="text-left">
                <button
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                    onClick={() => { setOrarioRicerca(new Date()); handleFetchTreni(); }}
                >
                    Cerca
                </button>
            </div>

            {showResults && !loading && (
                <>
                    {viewType === 'card' && <CardTreni treni={treni} />}
                    {viewType === 'table' && <TableTreni treni={treni} />}
                </>
            )}
        </div>
    );
}




















