// CardCollabb.jsx
import React from 'react';
import '../../assets/css/card-collabb.css';
import { FaInstagram } from 'react-icons/fa';

// Dati delle collaborazioni
const collaborations = [
  {
    name: 'Raimbow Spotted',
    description: 'Un profilo gossip nuovo che riguarda i gossip della Lomellina.',
    icon: require('../../assets/img/raindowspotted.jpg'), // Assicurati che il percorso sia corretto
    instagram: 'https://www.instagram.com/spotted_rainbow.lomellina/?utm_source=ig_web_button_share_sheet', // Inserisci il link corretto
  },
  {
    name: 'Spotted Caramuel',
    description: 'Lo spotted principale dell\'Istituto Caramuel di Vigevano.',
    icon: require('../../assets/img/spottedcaramuel.jpg'),
    instagram: 'https://www.instagram.com/spotted.caramuel_/?utm_source=ig_web_button_share_sheet',
  },
  {
    name: 'ITIS Caramuel',
    description: 'L\'istituto tecnico di Vigevano.',
    icon: require('../../assets/img/itislogo.jpg'),
    instagram: 'https://www.instagram.com/iis_caramuelroncalli?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
  },
  {
    name: 'NGL Vige',
    description: 'Profilo del gruppo NGL Cila, si occupa dei gossip di Vigevano.',
    icon: require('../../assets/img/nglvige.png'),
    instagram: 'https://www.instagram.com/ngl_vigee?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
  },
  {
    name: 'Crazy Lista',
    description: 'Profilo ufficiale dei rappresentanti dell\'ITIS Caramuel di Vigevano.',
    icon: require('../../assets/img/crazylista.jpg'),
    instagram: 'https://www.instagram.com/crazy.lista?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==',
  },
];

const CardCollabb = () => {
  return (
    <div className="ihome-collab-container">
      {collaborations.map((collab, index) => (
        <div className="ihome-collab-card" key={index}>
          <img src={collab.icon} alt={collab.name} className="ihome-collab-icon" />
          <h3>{collab.name}</h3>
          <p>{collab.description}</p>
          <a
            href={collab.instagram}
            target="_blank"
            rel="noopener noreferrer"
            className="ihome-instagram-button"
          >
            <FaInstagram className="ihome-instagram-icon" /> Visita su Instagram
          </a>
        </div>
      ))}
    </div>
  );
};

export default CardCollabb;
