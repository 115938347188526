import React from 'react';

const isTrenoSuburbano = (numeroTreno) => {
  const numeroStr = numeroTreno.toString();
  return numeroStr.startsWith('24') || numeroStr.startsWith('25');
};

const TableTreni = ({ treni }) => {
  return (
    <table className="min-w-full bg-white border-collapse border border-black mt-4">
      <thead className="bg-gray-800 text-white">
        <tr>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Compagnia</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Numero Treno</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Tipologia</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Destinazione</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Ora Partenza</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Ritardo</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Stato Treno</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Binario Programmato</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Binario Reale</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Categoria</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Descrizione Categoria</th>
          <th className="py-3 px-4 uppercase font-semibold text-sm border border-black">Binario Effettivo Partenza</th>
        </tr>
      </thead>
      <tbody className="text-gray-700">
        {treni.map((treno, index) => {
          let tipologiaTreno = treno.compTipologiaTreno;

          // Logica per treni con 4 cifre
          if (treno.numeroTreno.toString().length === 4) {
            if (treno.codiceCliente === 63) {
              tipologiaTreno = "Regio Express";
            } else {
              tipologiaTreno = "Regionale Veloce";
            }
          }

          // Logica per treni con 5 cifre
          else if (treno.numeroTreno.toString().length === 5) {
            if (isTrenoSuburbano(treno.numeroTreno)) {
              // Logica per treni suburbani con 5 cifre che iniziano per 24 o 25
              if (treno.numeroTreno.toString().startsWith('241')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/6/60/Milano_S1.svg";
              } else if (treno.numeroTreno.toString().startsWith('242')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/d/d6/Milano_S2.svg";
              } else if (treno.numeroTreno.toString().startsWith('245')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/0/00/Milano_S5.svg";
              } else if (treno.numeroTreno.toString().startsWith('246')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/3/34/Milano_S6.svg";
              } else if (treno.numeroTreno.toString().startsWith('247')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/3/34/Milano_S6.svg";
              } else if (treno.numeroTreno.toString().startsWith('248')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/9/94/Milano_S8.svg";
              } else if (treno.numeroTreno.toString().startsWith('249')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/0/01/Milano_S9.svg";
              } else if (treno.numeroTreno.toString().startsWith('243')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/3/3a/Milano_S13.svg";
              } else if (treno.numeroTreno.toString().startsWith('250')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/2/28/Milano_S11.svg";
              } else if (treno.numeroTreno.toString().startsWith('256')) {
                treno.tipologiaTrenoIcona = "https://upload.wikimedia.org/wikipedia/commons/8/8e/Milano_S12.svg";
              }

              tipologiaTreno = "Suburbano";
            } else {
              // Altrimenti sono treni regionali
              tipologiaTreno = "Regionale";
            }
          }

          return (
            <tr key={index}>
              <td className="py-3 px-4 border border-black">
                <img src={treno.iconaTreno} alt="Logo Compagnia Ferroviaria" className="w-16 h-auto object-contain" />
              </td>
              <td className="py-3 px-4 border border-black">{treno.numeroTreno}</td>
              <td className="py-3 px-4 border border-black">
                <img src={treno.tipologiaTrenoIcona} alt="Icona Tipologia Treno" className="w-8 h-auto object-contain inline-block mr-2" />
              </td>
              <td className="py-3 px-4 border border-black">{treno.destinazione}</td>
              <td className="py-3 px-4 border border-black">{new Date(treno.orarioPartenza).toLocaleTimeString()}</td>
              <td className="py-3 px-4 border border-black">{treno.ritardo} minuti</td>
              <td className="py-3 px-4 border border-black">{treno.statoTreno ? treno.statoTreno : "Informazioni non disponibili"}</td>
              <td className="py-3 px-4 border border-black">{treno.binarioProgrammato}</td>
              <td className="py-3 px-4 border border-black">{treno.binarioReale}</td>
              <td className="py-3 px-4 border border-black">{treno.categoria}</td>
              <td className="py-3 px-4 border border-black">{tipologiaTreno}</td>
              <td className="py-3 px-4 border border-black">{treno.binarioEffettivoPartenzaDescrizione}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableTreni;


