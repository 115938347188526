import React, { useState, useEffect } from "react";
import { FaInstagram, FaWhatsapp, FaNewspaper, FaStar } from "react-icons/fa";
import { ReactComponent as Anologo } from '../../assets/img/anonymouss.svg';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importa il CSS di AOS
import styles from '../../assets/css/IntestationHome.module.css';

function IntestationHome() {
  const [followers, setFollowers] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Inizializza AOS

    const fetchFollowers = async () => {
      try {
        const response = await fetch('https://fetchinstagramfollower-production.up.railway.app/instagram/followers');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFollowers(data.followers);
      } catch (error) {
        console.error('Error fetching Instagram followers:', error);
      }
    };

    fetchFollowers();
  }, []);

  return (
    <div className={styles["root-hm"]}>
      <div className={styles["background-hm"]}></div>
      <div className={styles["overlay-hm"]}></div>
      <div className={styles["content-hm"]} data-aos="fade-up">
        <h1 className={styles["title-hm"]}>
          <span className={styles["highlight-hm"]}>NGL CILA</span>, LO SPAZIO <br /> DEI GOSSIP!
        </h1>
        <p className={styles["typicalText-hm"]}>
          ESPLORA IL MONDO DEI GOSSIP E LE VARIE ESPERIENZE DI NGL CILA E NGL VIGE. SCOPRI QUA SOTTO CLICCANDO I PULSANTI ↓↓↓
        </p>
        {followers !== null && (
          <div className={styles["followersContainer-hm"]} data-aos="fade-up" data-aos-delay="300">
            <FaInstagram className={styles["followersIcon-hm"]} />
            <span className={styles["followersText-hm"]}>{followers} followers su Instagram </span>
          </div>
        )}
        <div className={styles["links-hm"]} data-aos="fade-up" data-aos-delay="600">
          <a href="https://www.instagram.com/ngl_cilaa" target="_blank" rel="noopener noreferrer" className={`${styles["link-hm"]} ${styles["instagram-hm"]}`}>
            <FaInstagram />  Instagram
          </a>
          <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer" className={`${styles["link-hm"]} ${styles["whatsapp-hm"]}`}>
            <FaWhatsapp />  WhatsApp
          </a>
          <a href="/news" target="_blank" rel="noopener noreferrer" className={`${styles["link-hm"]} ${styles["news-hm"]}`}>
            <FaNewspaper />  Notizie
          </a>
          <a href="/altro" target="_blank" rel="noopener noreferrer" className={`${styles["link-hm"]} ${styles["altro-hm"]}`}>
            <FaStar />  Altro
          </a>
          <a href="https://anonymouss.me/ngl_cila" target="_blank" rel="noopener noreferrer" className={`${styles["link-hm"]} ${styles["anonymous-hm"]}`}>
            <Anologo style={{ width: '24px', height: '24px', fill: 'white' }} /> Scrivi una domanda anonima
          </a>
        </div>
      </div>
    </div>
  );
}

export default IntestationHome;
















