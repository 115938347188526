import React from 'react';
import { FaHome, FaNewspaper, FaPhotoVideo, FaVideo, FaBus, FaTrain, FaCalendarAlt, FaGlobe, FaShoppingCart, FaDownload, FaBuilding } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/transitions.css'; // Importa il file CSS per le transizioni

const AltroSection = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    window.scrollTo(0, 0); // Scroll to top
    navigate(path);
  };

  const sections = [
    { name: 'Ritorna alla Home', path: '/home', icon: FaHome, description: 'Torna alla pagina principale.' },
    { name: 'News', path: '/news', icon: FaNewspaper, description: 'Leggi le ultime notizie.' },
    { name: 'Galleria', path: '/archiviofoto', icon: FaPhotoVideo, description: 'Esplora la nostra galleria fotografica.' },
    { name: 'Video', path: '/video', icon: FaVideo, description: 'Guarda i nostri video.' },
    { name: 'Orario Linee Autoguidovie', path: '/orarioautoguidovie', icon: FaBus, description: 'Consulta gli orari delle linee Autoguidovie.' },
    { name: 'Scopri il tuo Treno', path: '/scopriltuotreno', icon: FaTrain, description: 'Trova informazioni sui treni.' },
    { name: 'Eventi', path: '/eventi', icon: FaCalendarAlt, description: 'Scopri gli eventi in programma.' },
    { name: 'NGL Pop', path: '/ngl-pop', icon: FaGlobe, description: 'Esplora il nostro mondo.' },
    { name: 'Shop', path: '/shop', icon: FaShoppingCart, description: 'Visita il nostro negozio.' },
    { name: 'Scarica l\'App', path: '/downloadapp', icon: FaDownload, description: 'Scarica la nostra app.' },
    { name: 'Cila Studios (non disponibile)', path: '#', icon: FaBuilding, description: 'Scopri i nostri studi.' },
  ];

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-center text-3xl font-extrabold text-gray-900 mb-8">Menu</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {sections.map((section) => (
          <div
            key={section.name}
            onClick={() => handleClick(section.path)}
            className="flex flex-col items-center p-6 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition duration-300 cursor-pointer"
          >
            <section.icon className="h-12 w-12 mb-4" aria-hidden="true" />
            <h3 className="text-xl font-semibold">{section.name}</h3>
            <p className="mt-2 text-center">{section.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AltroSection;


