import React, { useState } from 'react';
import { useCombobox } from 'downshift';
import stations from '../../assets/olther/stations.json'; // Importa il file JSON delle stazioni
import LoadingPopup from "./LoadingPopup";
import '../../assets/css/inserisci-codice-treno.css'; // Importa il nuovo file CSS

function InserisciCodiceTreno() {
  const [codiceTreno, setCodiceTreno] = useState('');
  const [selectedStazione, setSelectedStazione] = useState('');
  const [loading, setLoading] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);  // Popup di caricamento
  const [trainDetails, setTrainDetails] = useState(null); // Stato per memorizzare i dettagli del treno
  const [stationsList, setStationsList] = useState(stations.slice(0, 20));
  const [moreStations, setMoreStations] = useState(stations.length > 20);
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedStazione) {
      alert("Per favore, seleziona una stazione di partenza.");
      return;
    }
    setLoading(true);
    setShowLoadingPopup(true);  // Mostra il popup di caricamento
    try {
      const selectedStation = stations.find(station => station.long_name === selectedStazione);
      const stazioneCode = selectedStation.code; // Utilizza 'code' come ID della stazione
      const date = new Date().getTime(); // Utilizza la data corrente
      const response = await fetch(`https://trenitaliapijs-production.up.railway.app/train/details/${stazioneCode}/${codiceTreno}/${date}`);
      const data = await response.json();
      setTrainDetails(data); // Memorizza i dettagli del treno
      console.log(data); // Visualizza i dati nel console log
    } catch (error) {
      console.error("Errore nel recupero dei dettagli del treno:", error);
    } finally {
      setLoading(false);
    }

    setTimeout(() => {
      setShowLoadingPopup(false);  // Nasconde il popup di caricamento dopo la durata della GIF
    }, 3000);  // Durata della GIF in millisecondi
  };

  const getTrainStatus = (tipoTreno, provvedimento) => {
    if (tipoTreno === 'PG' && provvedimento === 0) {
      return 'Treno regolare';
    }
    if (tipoTreno === 'ST' && provvedimento === 1) {
      return 'Treno soppresso';
    }
    if ((tipoTreno === 'PP' || tipoTreno === 'SI' || tipoTreno === 'SF') && (provvedimento === 0 || provvedimento === 2)) {
      return 'Treno parzialmente soppresso';
    }
    if (tipoTreno === 'DV' && provvedimento === 3) {
      return 'Treno deviato';
    }
    return 'Stato del treno non specificato';
  };

  const getTrainOperatorByCodiceCliente = (codiceCliente) => {
    switch (codiceCliente) {
      case 1:
        return 'FRECCIAROSSA/TRENITALIA AV';
      case 2:
        return 'TRENITALIA';
      case 4:
        return 'TRENITALIA INTERCITY';
      case 18:
        return 'TRENITALIA TPER';
      case 63:
        return 'TRENORD';
      case 64:
        return 'OBB';
      default:
        return 'Operatore sconosciuto';
    }
  };

  const loadMoreStations = () => {
    const newLength = stationsList.length + 20;
    setStationsList(stations.slice(0, newLength));
    setMoreStations(newLength < stations.length);
  };

  const filteredStations = (inputValue) => {
    return stations.filter(station =>
      station.long_name.toLowerCase().includes(inputValue.toLowerCase())
    ).slice(0, 20);
  };

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getLabelProps,
    getToggleButtonProps,
    getItemProps,
    highlightedIndex,
    selectedItem,
  } = useCombobox({
    items: filteredStations(inputValue),
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue);
      setStationsList(filteredStations(inputValue));
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        if (selectedItem.code === "load_more") {
          loadMoreStations();
        } else {
          setSelectedStazione(selectedItem.long_name);
        }
      }
    },
    itemToString: (item) => (item ? item.long_name : ''),
  });

  const defaultOptions = stationsList.map(station => ({
    long_name: station.long_name,
    code: station.code
  }));

  if (moreStations) {
    defaultOptions.push({ long_name: "Mostra altre stazioni...", code: "load_more" });
  }

  return (
    <div className="inserisci-codice-treno-container">
      <h2 className="inserisci-codice-treno-title">Inserisci il codice del treno e la stazione di provenienza</h2>
      <form onSubmit={handleSubmit} className="inserisci-codice-treno-form">
        <div className="inserisci-codice-treno-field">
          <label htmlFor="codiceTreno">Codice del Treno</label>
          <input
            type="text"
            id="codiceTreno"
            placeholder="Es. 7524"
            value={codiceTreno}
            onChange={(e) => setCodiceTreno(e.target.value)}
          />
        </div>
        <div className="inserisci-codice-treno-field">
          <label htmlFor="stazioneProvenienza">Stazione di Provenienza</label>
          <div {...getToggleButtonProps()} className="inserisci-codice-treno-select-wrapper">
            <input
              {...getInputProps()}
              className="inserisci-codice-treno-select-input"
              placeholder="Seleziona una stazione..."
            />
            <span className="select-arrow">▼</span>
          </div>
          <ul {...getMenuProps()} className="inserisci-codice-treno-menu">
            {isOpen && 
              defaultOptions.map((item, index) => (
                <li
                  key={index}
                  {...getItemProps({ item, index })}
                  className={`inserisci-codice-treno-menu-item ${highlightedIndex === index ? 'inserisci-codice-treno-highlighted-item' : ''}`}
                >
                  {item.long_name}
                </li>
              ))
            }
          </ul>
        </div>
        <div className="inserisci-codice-treno-actions">
          <button
            type="submit"
            disabled={loading}
          >
            {loading ? 'Caricamento...' : 'Conferma'}
          </button>
        </div>
      </form>
      {showLoadingPopup && <LoadingPopup duration={3000} onEnd={() => setShowLoadingPopup(false)} />}  {/* Mostra il popup di caricamento */}
      {trainDetails && (
        <div className="inserisci-codice-treno-details">
          <h3>
            
            Dettagli del Treno
          </h3>
          <div className="train-info">
            <p><strong>Numero Treno:</strong> {trainDetails.numeroTreno}</p>
            <p><strong>Categoria:</strong> {trainDetails.categoria}</p>
            <p><strong>Partenza (Origine):</strong> {trainDetails.origine}</p>
            <p><strong>Destinazione:</strong> {trainDetails.destinazione}</p>
            <p><strong>Ritardo:</strong> {trainDetails.ritardo} minuti</p>
            <p><strong>Stato del Treno:</strong> {getTrainStatus(trainDetails.tipoTreno, trainDetails.provvedimento)}</p>
            <p>
              <strong>Operatore:</strong> {getTrainOperatorByCodiceCliente(trainDetails.codiceCliente)}
            </p>
            <p><strong>Binario Programmato di Partenza:</strong> {trainDetails.binarioProgrammatoPartenzaDescrizione}</p>
            <p><strong>Binario Effettivo di Partenza:</strong> {trainDetails.binarioEffettivoPartenzaDescrizione}</p>
            <p><strong>Binario Effettivo di Arrivo:</strong> {trainDetails.binarioEffettivoArrivoDescrizione}</p>
            <p><strong>Orario di Partenza:</strong> {trainDetails.compOrarioPartenza}</p>
            <p><strong>Orario di Arrivo:</strong> {trainDetails.compOrarioArrivo}</p>
            <p><strong>Durata del Viaggio:</strong> {trainDetails.compDurata}</p>
            <p><strong>Stazioni di passaggio:</strong></p>
            <ul className="stazioni-list">
              {trainDetails.fermate && trainDetails.fermate.map((stazione, index) => (
                <li key={index}>{stazione.stazione}</li>
              ))}
            </ul>
            <p><strong>Ultimo Rilevamento:</strong> {trainDetails.stazioneUltimoRilevamento}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default InserisciCodiceTreno;

         

            

